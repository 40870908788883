.login {
    background-color: #e6fcf5 !important;
}

.root {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: scroll;
	height: 100%;
}