body {
  margin: 0;
  padding: 0;
  background-color: #23485c;
}

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@200;500;800&display=swap');


.text-label {
	@media (max-width: 768px) {
		display: block;
		background-color: #23485c;
	}

}

.textButton {
	cursor: pointer;
	display: block;
	color: unset;
	text-decoration: none;
}

.textButton:hover {
	text-decoration: underline;
}